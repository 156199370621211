import React from "react";
import { graphql } from "gatsby";
import { CastAndCrewProps, NavigatedToState } from "src/types";
import Layout from "../components/layout";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { formatDate } from "../utils/formatDate";
import SEO from "../components/seo";
import FilmCard from "../components/filmCard";
import ArticleCard from "../components/articleCard";
import GoBack from "../components/GoBack";
import FilmList from "../components/FilmList";

const useStyles = makeStyles(theme => ({
  gridItem: {
    margin: theme.spacing(0, 0, 0, 2)
  },
  text: {
    alignText: "justify"
  },
  grid: {
    display: "flex",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  img: { width: 200, float: "right", margin: theme.spacing(0, 0, 2, 2) },
  space: { height: theme.spacing(2) }
}));

export default function Template({
  data: {
    markdownRemark: castAndCrew,
    allMarkdownRemark: allFilms,
    relatedArticles,
    allFilmCsv
  },
  location
}: CastAndCrewProps & NavigatedToState) {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title={castAndCrew.frontmatter.name} />
      <GoBack navigatedTo={location?.state?.navigatedTo} />
      <Divider className={classes.divider} />
      <div className={classes.grid}>
        <Typography variant="h4" component="h1">
          {castAndCrew.frontmatter.name}
        </Typography>
        <Typography className={classes.gridItem}>
          *{formatDate(castAndCrew.frontmatter.born)}
        </Typography>
        {castAndCrew.frontmatter.died && (
          <Typography className={classes.gridItem}>
            ✝{formatDate(castAndCrew.frontmatter.died)}
          </Typography>
        )}
      </div>
      <div className={classes.space} />
      <Typography variant="h6">Reviews</Typography>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {allFilms.edges
          .filter(
            ({ node }) =>
              node.frontmatter.director === castAndCrew.frontmatter.name
          )
          .sort(
            ({ node: a }, { node: b }) =>
              new Date(b.frontmatter.premiere).getTime() -
              new Date(a.frontmatter.premiere).getTime()
          )
          .map(({ node: film }) => (
            <div style={{ marginRight: 8, marginBottom: 12 }}>
              <FilmCard size="m" film={film} />
            </div>
          ))}
      </div>
      <div className={classes.space} />
      <Typography variant="h6">All Ratings</Typography>
      <FilmList data={{ allFilmCsv }} />
      <div className={classes.space} />
      <div style={{ display: "flex", marginBottom: 30 }}>
        <MuiLink
          href={`https://letterboxd.com${castAndCrew.frontmatter.letterboxd}`}
        >
          letterboxd
        </MuiLink>
        <MuiLink
          style={{ marginLeft: 20 }}
          href={`https://imdb.com/name/${castAndCrew.frontmatter.imdb}`}
        >
          imdb
        </MuiLink>
      </div>
      {relatedArticles.edges[0] ? (
        <Typography variant="h5">Related:</Typography>
      ) : null}
      <div style={{ display: "flex" }}>
        {relatedArticles.edges.map((article: any) => (
          <ArticleCard
            key={article.node.frontmatter.path}
            article={article.node}
          />
        ))}
      </div>
    </Layout>
  );
}

export const postQuery = graphql`
  query CastAndCrewByPath($path: String!, $director: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        name
        born
        died
        mainOccupation
        greatest
        overlooked
        worst
        imdb
        letterboxd
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath:{regex:"/films/.*\\.md$/"} }) {
      edges {
        node {
          frontmatter {
            path
            title
            date
            director
            premiere
            poster {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    relatedArticles: allMarkdownRemark(filter: { fileAbsolutePath:{regex:"/articles/.*\\.md$/"}, frontmatter:{ features: {eq:$path} } }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            path
            date
            author
            title
          }
        }
      }
    }
    allFilmCsv(filter: { directors: { regex: $director } }) {
      nodes {
        eTitle
        oTitle
        releaseDate
        rewatched
        imdb
        directors
        rating
        year
      }
    }
  }
`;
